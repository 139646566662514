import * as React from "react"

const LINKEDIN_SETTINGS = {
    redirect_url: 'https://genvalues.com/api/auth/linkedin/callback',
    client_id: '86ql2u5scqyk2c',
    base_url: 'https://www.linkedin.com/oauth/v2/authorization'
}

export default function LinkedinLogin({redirectUrl, onClose, company}) {
    return <div onClick={() => {
        onClose && onClose(true);
        const state = encodeURIComponent(JSON.stringify({
            redirectUrl,
            company
        }))
        setTimeout(() => {
            window.open(`${LINKEDIN_SETTINGS.base_url}?response_type=code&client_id=${LINKEDIN_SETTINGS.client_id}&redirect_uri=${LINKEDIN_SETTINGS.redirect_url}&state=${state}&scope=r_emailaddress,r_liteprofile`);
        });

    }}>
        <img src={'/img/linkedin-login.png'} alt={'Linkedin Login'} width={202} height={40}/>
    </div>
}
