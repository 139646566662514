/**
 * This page is start page V2. Contactor: shihaoq
 */
import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import EmptyLine from "../ui/EmptyLine";
import "./StartPageV2.less";
import {Row, Col, message} from "antd";
import StartFooter from "../component/start/StartFooter";
import {useHistory, useLocation} from 'react-router';
import {AJButton4, AJCenterButton} from "../ui/AJButton3";
import {getFavorite, sendCommunityMail, getAllCompanies} from "../http/AJHttp";
import {AJEmailInput} from "../ui/AJInput2";
import SvgClose from "../icons/Close";
import {isFreeEducationFTU, setJoinedCommunity} from "../storage/AJStorage";
import AJLoading from "../ui/AJLoading";
import AJModalV2 from "../ui/AJModalV2";
import {useOnClickOutside} from "../hooks/common";
import {useDispatch, useSelector} from "react-redux";
import {selectMatches, selectMatchesDone, selectMatchesPage} from "../redux/slices/start";
import _ from "lodash";
import CEOReviewCardContainer from "../component/ceo-review/CEOReviewCardContainer";
import StepDescriptionCard from '../component/steps-description-card/stepDescriptionCard';
import {WelcomeModal} from "./toolbox/QuestionsInInterview";
import Slider from '../component/image-scroll-player/imageScrollPlayer';
import {gotoLink} from "../component/start/StartFooter";
import { companyWithHighPriority, companyWithSecondPriority, shouldIgnoreCompany } from '../component/values/ValuesConfig';

const setCardBottom = () => {
    const footerEl = document.getElementById('aj-start-footer')
    const el = document.getElementsByClassName('join-community-card-start-page-show-2')[0]
    if (el && footerEl) {
        let offset = window.scrollY - footerEl.offsetTop
        offset = offset > 0 ? offset : 0
        el.style.bottom = `${offset}px`
    }
}

export function JoinCommunity() {
    const [sending, setSending] = useState(false)
    const [email, setEmail] = useState('')
    const offset = window.innerWidth - 260
    const [cardClass, setCardClass] = useState('join-community-card-start-page')

    return (
        <div className={cardClass} style={{left : offset}}>
            <div className={'join-community-card'} id={'aj-join-community-card-start-page'}>
                <div style={{
                    position: 'absolute',
                    top: 20,
                    right: 16
                }} onClick={() => {
                    setCardClass('join-community-card-start-page')
                    // setHiddenJoinCommunity(true)
                }}>
                    <SvgClose fill={'white'} width={24} height={24}/>
                </div>
                <div className={'join-community-card-container'}>
                    <div className={'join-community-card-title'}>
                        Stay Ahead
                    </div>
                    <br/>
                    <div className={'join-community-card-desc'}>
                        Sign up for weekly <br/>newsletter
                    </div>
                    <EmptyLine height={40}/>
                    <AJEmailInput label={'Enter your email'}
                                  id={'aj-community-mail'}
                                  defaultValue={email}
                                  width={240}/>

                    <EmptyLine height={32}/>
                    <AJCenterButton title={'OK'}
                                    size={'small'}
                                    scheme={'white'}
                                    loading={sending}
                                    height={38}
                                    width={207}
                                    onClick={async ()=>{
                                        const email = document.getElementById('aj-community-mail').value
                                        setSending(true)

                                        const response = await sendCommunityMail(email)
                                        if (response) {
                                            message.info("Thank you for subscribing!")
                                            setCardClass('join-community-card-start-page')
                                            setJoinedCommunity()
                                        }
                                        else {
                                            message.error("Subscribe failed!")
                                        }

                                        // document.getElementById('aj-community-mail').value = null
                                        setSending(false)
                                    }}/>
                    <EmptyLine height={10}/>
                    <div className={'join-community-card-privacy'} onClick={()=>{
                        window.open('/privacy')
                    }}>
                        Privacy
                    </div>
                </div>
            </div>
        </div>)
}

export function JoinCommunity2() {
    const [sending, setSending] = useState(false)
    const [email, setEmail] = useState('')
    const [visible, setVisible] = useState(false);
    const [className, setClassName] = useState('');
    const ref = useRef();
    const matches = useSelector(selectMatches);

    useLayoutEffect(() => {
        if (matches?.length) {
            return;
        }
        // if (!getJoinedCommunity()) {
        //     setTimeout(() => {
        //         setVisible(true);
        //         setClassName('join-community-card-animation');
        //     }, 7000);
        // }
    }, []);
    const close = () => {
        setClassName('');
        setVisible(false);
    };
    useOnClickOutside(ref, [], () => {
        close();
    });
    return (
        <div className={className} style={{position: 'fixed', left: 'calc(50% - 220px)', top: '20%', zIndex: 99}} hidden={!visible} ref={ref}>
                <div className={'join-community-card'} id={'aj-join-community-card-start-page'} style={{width: 483, height: 284}}>
                    <div style={{
                        position: 'absolute',
                        top: 20,
                        right: 16,
                        pointer: 'cursor'
                    }} onClick={() => {
                        close();
                    }}>
                        <SvgClose fill={'white'} width={24} height={24}/>
                    </div>
                    <div className={'join-community-card-container'} style={{paddingTop: 30}}>
                        <div className={'join-community-card-title'} style={{textAlign: 'center'}}>
                            Stay Ahead
                        </div>
                        <br/>
                        <div className={'join-community-card-desc'} style={{textAlign: 'center'}}>
                            Get team profiles and jobs <br/>in your inbox.
                        </div>
                        <EmptyLine height={30}/>
                        <div style={{margin: 'auto', paddingLeft: 30}}>
                            <AJEmailInput label={'Enter your email'}
                                          id={'aj-community-mail'}
                                          defaultValue={email}
                                          width={240}/>
                        </div>


                        <EmptyLine height={20}/>
                        <AJCenterButton title={'OK'}
                                        size={'small'}
                                        scheme={'white'}
                                        loading={sending}
                                        height={38}
                                        width={207}
                                        onClick={async ()=>{
                                            const email = document.getElementById('aj-community-mail').value
                                            setSending(true)

                                            const response = await sendCommunityMail(email)
                                            if (response) {
                                                message.info("Thank you for subscribing!")
                                                setJoinedCommunity();
                                                close();
                                            }
                                            else {
                                                message.error("Subscribe failed!")
                                            }

                                            // document.getElementById('aj-community-mail').value = null
                                            setSending(false)
                                        }}/>
                        <EmptyLine height={10}/>
                        <div className={'join-community-card-privacy'} onClick={()=>{
                            window.open('/privacy')
                        }}>
                            Privacy
                        </div>
                    </div>
                </div>
        </div>)
}

export function StartPageWrapper(props) {


    const footer = <StartFooter/>

    return (<>
        <StartPageV2 {...props} footer={footer}/>
        {/*<JoinCommunity/>*/}
    </>)
}

function StartPageV2(props) {
    let history = useHistory();
    const location = useLocation();

    const {isLogin} = props

    // window.scrollTo(0,0)
    const matches = useSelector(selectMatches);
    const matchesDone = useSelector(selectMatchesDone);
    const page = useSelector(selectMatchesPage);
    const dispatch = useDispatch();

    const [searching, setSearching] = useState(false)
    const [companyLogoUrlLineOne, setCompanyLogoUrlLineOne] = useState([])
    const [companyLogoUrlLineTwo, setCompanyLogoUrlLineTwo] = useState([])
    const [companyLogoUrlLineThree, setCompanyLogoUrlLineThree] = useState([])
    const [jobNum, setJobNum] = useState(0)
    const [needLoadingCompanyLogos, setNeedLoadingCompanyLogos] = useState(false)

    const [showQuestionInterviewWelcome, setShowQuestionInterviewWelcome] = useState(false);

    const [offsetHeight, setOffsetHeight] = useState('103vh')

    const onResize = () => {
        // const videoHeight = document.getElementById("aj-background-video").offsetHeight
        // setOffsetHeight(videoHeight +  120)
    }
    
    useEffect(() => {
        const cachedData = JSON.parse(sessionStorage.getItem('cachedCompanyInfo'));
        if (cachedData) {
            getCompanyLogoUrl(cachedData);
            return;
        }
        setNeedLoadingCompanyLogos(true);
        fetchAllCompanyData();
    }, [location, history])

    useEffect(() => {
        if (!matches?.length) {
            window.scroll(0, 0);
        }
    }, []);

    useEffect(() => {
        if (isFreeEducationFTU()) {
            setTimeout(() => {
                setShowQuestionInterviewWelcome(true)
            }, 4000)
        }
    }, [])

    const fetchAllCompanyData = async () => {
        if (searching) {
            return
        }
        setSearching(true)

        const response = await getAllCompanies()
        if (response) {
            if (response.data && response.data.length > 0) {
                sessionStorage.setItem('cachedCompanyInfo', JSON.stringify(response.data));
                getCompanyLogoUrl(response.data ?? []);
                setNeedLoadingCompanyLogos(false);
            }
        }
        else {
            message.error("search all company logo failed")
        }
        setSearching(false)
    }

    function getCompanyLogoUrl(companyInfo) {
        let jobNum = 0;
        let tmpLineOne = [];
        let tmpLineTwo = [];
        let tmpOther = [];
        for (let idx in companyInfo) {
            if (companyInfo[idx]?.companyStructure?.logoUrl && !shouldIgnoreCompany.includes(companyInfo[idx].name.toLowerCase())) {
                if (companyWithHighPriority.includes(companyInfo[idx].name.toLowerCase())) {
                    tmpLineOne.push(['https://genvalues.com/' + companyInfo[idx].companyStructure.logoUrl, companyInfo[idx].name, companyInfo[idx].id, companyInfo[idx].published]);
                } else if (companyWithSecondPriority.includes(companyInfo[idx].name.toLowerCase()) || idx % 2) {
                    tmpLineTwo.push(['https://genvalues.com/' + companyInfo[idx].companyStructure.logoUrl, companyInfo[idx].name, companyInfo[idx].id, companyInfo[idx].published]);
                } else {
                    tmpOther.push(['https://genvalues.com/' + companyInfo[idx].companyStructure.logoUrl, companyInfo[idx].name, companyInfo[idx].id, companyInfo[idx].published]);
                }
            }

            if(companyInfo[idx].published && companyInfo[idx].jobOpenings) {
                jobNum += companyInfo[idx].jobOpenings.length;
            }
        }
        setCompanyLogoUrlLineOne(tmpLineOne);
        setCompanyLogoUrlLineTwo(tmpLineTwo);
        setCompanyLogoUrlLineThree(tmpOther);
        setJobNum(jobNum);
    }

    const fetchFavoriteData = async () => {
        const response = await getFavorite();
        if (response) {
            //setCards(response.data)
        }
        else {
            message.error("Get favorite failed")
        }
        // console.log(response.data)
    }

    function navigateToCompanyPage(companyId, isPublished) {
        if(isPublished) gotoLink(`/company-profile/${companyId}`, history)
    }

    return (
        <div className={'start-page'}>
            <div className={'start-page-container'}>
                <div className={'value-promotion'}>
                    <div>Find Engineering Teams You Want</div>
                    <div>To Work With</div>
                </div>

                <EmptyLine height={45}/>

                <div className={'start-button-container'}>
                    <AJButton4
                        title={'Start Matching'}
                        size={'mini'}
                        width={319}
                        type={'secondary'}
                        height={67}
                        titleStyle={{
                            fontSize: 25,
                            fontWeight: 400,
                            fontFamily: 'ManropeBold',
                            color: '#000000'
                        }}
                        customStyle={{
                            borderRadius: '90px',
                            background: '#FFD865',
                        }}
                        hoverToBlack={true}
                        titleWithWaveAnimation={true}
                        onClick={() => {
                            gotoLink('/value-select', history)
                        }}/>
                    {jobNum ? <div className={"job-num"}>{jobNum} top Engineering jobs</div> : <></>}
                </div>

                <EmptyLine height={131}/>
            </div>

            {
                <div className={'logo-scroll-container'}>
                    {
                        needLoadingCompanyLogos ? <div style={{textAlign: 'center'}}>
                        <AJLoading size={50}/>
                        <EmptyLine height={30}/>
                    </div> : 
                    <div>
                        <Slider durationInMs="4500" start="0">
                            {
                                companyLogoUrlLineOne.map(logo => {
                                    return (
                                        <img className={'logo-img-scroll'}
                                         style={{cursor: logo[3] ? 'pointer' : ''}}
                                         src={`${logo[0]}`} alt={`${logo[1]}`}
                                         onClick={()=>navigateToCompanyPage(logo[2], logo[3])}></img>
                                    )
                                })
                            }
                        </Slider>
                        <Slider durationInMs="4500" start="0" customContainerStyle={{'padding-left': '160px', 'margin-top': '75px', 'margin-bottom': '75px'}}>
                            {
                                companyLogoUrlLineTwo.map(logo => {
                                    return (
                                        <img className={'logo-img-scroll'}
                                         style={{cursor: logo[3] ? 'pointer' : ''}}
                                         src={`${logo[0]}`} alt={`${logo[1]}`} 
                                         onClick={()=>navigateToCompanyPage(logo[2], logo[3])}></img>
                                    )
                                })
                            }
                        </Slider>
                        <Slider durationInMs="4500" start="0">
                            {
                                companyLogoUrlLineThree.map(logo => {
                                    return (
                                        <img className={'logo-img-scroll'} 
                                         style={{cursor: logo[3] ? 'pointer' : ''}} 
                                         src={`${logo[0]}`} alt={`${logo[1]}`} 
                                         onClick={()=>navigateToCompanyPage(logo[2], logo[3])}></img>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    }
                    <EmptyLine height={110}/>
                </div>
            }

            <div className={'step-description-container'}>
                <div className={'step-title'}>How it works</div>
                <div>
                    <Row justify={'center'} align={'stretch'} type={'flex'}>
                        <Col span={6} style={{top: '111px'}}>
                            <StepDescriptionCard content={'/img/home/step1.svg'} textOne={'Select your'} textTwo={'values'} hasLink={true}/>
                        </Col>
                        <Col span={2} style={{top: '111px', left: '-50px'}}>
                            <img src={"/img/home/narrow.svg"}></img>
                        </Col>
                        <Col span={6} style={{top: '72px'}}>
                            <StepDescriptionCard content={'/img/home/step2.svg'} textOne={'Select your'} textTwo={'technology skills'}/>
                        </Col>
                        <Col span={2} style={{top: '72px'}}>
                            <img src={"/img/home/narrow.svg"}></img>
                        </Col>
                        <Col span={6} style={{top: '33px'}}>
                            <StepDescriptionCard content={'/img/home/step3.svg'} textOne={'We get tech teams'} textTwo={'matched for you'}/>
                        </Col>
                    </Row>
                    {/* <div>
                        <img src={'/img/home/talentLink.svg'}></img>
                    </div> */}
                </div>

                <EmptyLine height={30}/>
            </div>

            <CEOReviewCardContainer />

            <EmptyLine height={165}/>
            {props.footer}

            <AJModalV2 visible={showQuestionInterviewWelcome}
                       width={400}
                       closable={true}
                       centered={true}
                       destroyOnClose={true}
                       onClose={() => {
                           setShowQuestionInterviewWelcome(false)
                       }}
            >
                <WelcomeModal onClose={() => {
                    setShowQuestionInterviewWelcome(false)
                    setTimeout(() => {
                        history.push("/toolbox")
                    }, 200)
                }}/>
            </AJModalV2>

            <div style={{'display': 'none'}}>
              <img src={"/img/value-select/select-hint.svg"}></img>
            </div>
        </div>
    )
}

export default StartPageV2;
